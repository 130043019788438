import { AssistantModel, assistantSuggestPromptsApi, IConversationHistory, listAssistantsApi, listConversationsHistory, SuggestPrompts } from "@api";
import { getAccessToken } from "@authConfig";
import { IPublicClientApplication } from "@azure/msal-browser";
import { currentAssistantAtom, msalInstanceAtom } from "@state/Atoms";
import { handleGetTokenError } from "@utils/auth";
import { atomWithQuery } from "jotai-tanstack-query";

export const assistantsQueryAtom = atomWithQuery<AssistantModel[]>((get) => ({
  queryKey: ["assistantList", get(msalInstanceAtom)],
  queryFn: async ({ queryKey: [, instance] }) => {
    if (!instance) return [];

    const token = await getAccessToken(instance as IPublicClientApplication);

    if (!token) {
      handleGetTokenError();
    }

    try {
      const response = await listAssistantsApi(token);

      if (!response?.body) {
        return [];
      }

      const result: AssistantModel[] = await response.json();

      return result;
    } catch (error) {
      console.group("AssistantList")
      console.info("Não foi possível listar assistentes. Erro:");
      console.error(error);
      console.groupEnd();
    }

    return [];
  },
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  staleTime: 30 * 60 * 1000, // 30 minutes
}))

export const assistantPromptSuggestionsQueryAtom = atomWithQuery<string[]>((get) => ({
  queryKey: ["promptSuggestion", get(currentAssistantAtom), get(msalInstanceAtom)],
  queryFn: async ({ queryKey: [, assistant, instance] }) => {
    if (!instance || !assistant) return [];

    const token = await getAccessToken(instance as IPublicClientApplication);
    const currentAssistant = assistant as AssistantModel

    if (!token) {
      handleGetTokenError();
    }
    
    try {
      const response = await assistantSuggestPromptsApi(token, currentAssistant.id)

      if (!response?.body) {
        return [];
      }

      const result: SuggestPrompts = await response.json();

      return result.promptSuggestions;
    } catch (error) {
      console.group("PromptSuggestions")
      console.info("Não foi possível listar sugestões de prompt. Erro:");
      console.error(error);
      console.groupEnd();
    }

    return []
  },
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  staleTime: 30 * 60 * 1000, // 30 minutes
  gcTime: 30 * 60 * 1000, // 30 minutes
}))

export const chatHistoryQueryAtom = atomWithQuery<IConversationHistory[]>((get) => ({
  queryKey: ["chatHistory", get(msalInstanceAtom)],
  queryFn: async ({ queryKey: [, instance] }) => {
    if (!instance) return [];

    const token = await getAccessToken(instance as IPublicClientApplication);

    if (!token) {
      handleGetTokenError();
    }

    try {
      const response = await listConversationsHistory(token);

      if (!response?.body) {
        return [];
      }

      const result: IConversationHistory[] = await response.json();

      return result;
    } catch (error) {
      console.group("Conversation history")
      console.info("Não foi possível listar o histórico de conversas. Erro:");
      console.error(error);
      console.groupEnd();
    }

    return [];
  },
  refetchOnReconnect: true,
  refetchOnWindowFocus: true,
  staleTime: 30 * 60 * 1000, // 30 minutes
}))