import styles from "./Login.module.css";
import LisLogo from "@assets//LisLogo-Verde.png";
import { InteractionStatus } from "@azure/msal-browser";
import { useEffect, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "@authConfig";
import { Modal } from "@components/Modal/Modal";
import EnablePopUps from "@assets/enable-pop-up.png";
import { redirect } from "react-router-dom";
import { isInIframeAtom } from "@state/Atoms";
import { useAtom } from "jotai";

interface ErrorModal {
  title: string;
  text: string;
  displayImage: undefined | string;
}

export default function LoginTeamsStart() {
  const [errorModal] = useState<ErrorModal>({
    title: "Erro no login",
    text: "Não foi possível realizar seu login. Entre em contato com o administrador do sistema.",
    displayImage: undefined,
  });

  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const loginButtonRef = useRef(null);
  const { instance, inProgress } = useMsal();
  const [isInIframe] = useAtom(isInIframeAtom);

  const handleLoginRedirect = () => {
    console.log(window.location.origin);
    const redirectUri = window.location.origin + "/teams-login-end";
    console.log(redirectUri);
    instance
      .loginRedirect({
        ...loginRequest,
        redirectUri,
      })
      .catch((error) => {
        console.log(error);
        redirect("/?loginError=error");
      });
  };

  useEffect(() => {
    if (!verifyAuthInProgress() && !instance.getActiveAccount()) {
      if (!isInIframe) {
        const urlParams = new URLSearchParams(window.location.search);
        const loginError = urlParams.get("logout");
        if (!loginError) {
          handleLoginRedirect();
        }
      }
    }
  }, [instance, inProgress, loginButtonRef]);

  const verifyAuthInProgress = () => {
    return inProgress !== InteractionStatus.None;
  };

  const returnTextInFrameLogin = () => {
    return isInIframe
      ? "Estamos realizando seu login...\n Permita os pop-ups para finalizar o acesso!"
      : "Estamos realizando seu login...";
  };

  const returnImageInFrameLogin = () => {
    return isInIframe ? EnablePopUps : undefined;
  };

  useEffect(() => {
    verifyLoginError();
  }, []);

  const verifyLoginError = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const loginError = urlParams.get("loginError");
    if (loginError) {
      setShowErrorModal(true);
    }
  };

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  return (
    <>
      <Modal
        modalType="wait"
        open={verifyAuthInProgress()}
        text={returnTextInFrameLogin()}
        title="Aguarde"
        displayImage={returnImageInFrameLogin()}
      />
      <Modal
        modalType="error"
        open={showErrorModal}
        text={errorModal.text}
        title={errorModal.title}
        displayImage={errorModal.displayImage}
        buttonCloseText="Fechar"
        closeModalFunc={handleCloseErrorModal}
      />
      <div className={styles.loginContainer}>
        <div className={styles.loginLayout}>
          <div>
            <img src={LisLogo} className={styles.chatIcon} aria-hidden="true" />
            <h2 className={styles.headText}>Bem-vindo à Lis </h2>
            <p className={styles.subText}>
              Eu sou a assistente virtual da Libbs!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
