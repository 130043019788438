import React from "react";
import ReactDOM from "react-dom/client";
import { initializeIcons } from "@fluentui/react";
import { BrowserRouter as Router } from "react-router-dom";

import "./index.css";

import {
  PublicClientApplication,
  EventType,
  AuthenticationResult,
  EventMessage,
} from "@azure/msal-browser";
import { msalConfig } from "@authConfig";
import App from "./App";

interface AppProps {
  instance: PublicClientApplication;
}

initializeIcons();

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.initialize().then(() => {
  msalInstance.addEventCallback((event: EventMessage) => {
    console.log(event.eventType);
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
      <Router>
        <App instance={msalInstance} />
      </Router>
    </React.StrictMode>
  );
});
