import { Modal as ComponentsModal } from "@components/Modal/Modal";

interface ILoadingProps {
  text?: string;
}

export default function Loading({ text }: ILoadingProps) {
  return (
    <ComponentsModal
      open={true}
      title="Aguarde"
      text={text ?? "Carregando..."}
      modalType={"wait"}
    />
  );
}
