import React, {
  createContext,
  useReducer,
  ReactNode,
  useEffect,
  useMemo,
} from "react";
import { appStateReducer } from "./AppReducer";
import {
  ChatHistoryLoadingState,
  CosmosDBHealth,
  historyList,
  historyEnsure,
  CosmosDBStatus,
  User,
  Conversation,
  IConversationHistory,
} from "@api";
import { atomWithReducer } from "jotai/utils";
import { appStateAtom } from "@state/Atoms";
import { useAtom } from "jotai";

export interface AppState {
  isChatHistoryOpen: boolean;
  chatHistoryLoadingState: ChatHistoryLoadingState;
  isCosmosDBAvailable: CosmosDBHealth;
  chatHistory: Conversation[] | null;
  filteredChatHistory: Conversation[] | null;
  currentChat: Conversation | null;
  user: User | null;
}

export type Action =
  | { type: "TOGGLE_CHAT_HISTORY" }
  | { type: "SET_COSMOSDB_STATUS"; payload: CosmosDBHealth }
  | { type: "NEW_CHAT" }
  | { type: "SELECT_CHAT", payload: IConversationHistory }
  | {
      type: "UPDATE_CHAT_HISTORY_LOADING_STATE";
      payload: ChatHistoryLoadingState;
    }
  | { type: "UPDATE_CURRENT_CHAT"; payload: Conversation | null }
  | { type: "UPDATE_FILTERED_CHAT_HISTORY"; payload: Conversation[] | null }
  | { type: "UPDATE_CHAT_HISTORY"; payload: Conversation } // API Call
  | { type: "UPDATE_CHAT_TITLE"; payload: Conversation } // API Call
  | { type: "DELETE_CHAT_ENTRY"; payload: string } // API Call
  | { type: "DELETE_CHAT_HISTORY" } // API Call
  | { type: "DELETE_CURRENT_CHAT_MESSAGES"; payload: string } // API Call
  | { type: "FETCH_CHAT_HISTORY"; payload: Conversation[] | null } // API Call
  | { type: "UPDATE_USER_INFO"; payload: User | null }; // Set User Basic Info

export const AppStateContext = createContext<
  | {
      state: AppState;
      dispatch: React.Dispatch<Action>;
    }
  | undefined
>(undefined);

type AppStateProviderProps = {
  children: ReactNode;
};

export const AppStateProvider: React.FC<AppStateProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useAtom(appStateAtom);
  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
};
