import { Conversation } from "@api";
import { Action, AppState } from "./AppProvider";

// Define the reducer function
export const appStateReducer = (state: AppState, action: Action): AppState => {
  console.log("appStateReducer", state, action);
  switch (action.type) {
    case "TOGGLE_CHAT_HISTORY":
      return { ...state, isChatHistoryOpen: !state.isChatHistoryOpen };
    case "UPDATE_CURRENT_CHAT":
      return { ...state, currentChat: action.payload };
    case "SELECT_CHAT":
      if (!action.payload || state.currentChat?.id === action.payload.id) {
        return state;
      }

      const newState: Conversation = {
        id: action.payload.id,
        title: action.payload.title,
        date: action.payload.timestamp,
        assistant_id: action.payload.assistant_id,
        messages: action.payload.messages.map((message) => ({
          ...message,
          date: new Date(message.timestamp).toISOString(),
          citations: message.documents_used
        })),
      }

      return { ...state, currentChat: newState };
    case "UPDATE_CHAT_HISTORY_LOADING_STATE":
      return { ...state, chatHistoryLoadingState: action.payload };
    case "UPDATE_CHAT_HISTORY":
      if (!state.chatHistory || !state.currentChat) {
        return state;
      }
      const conversationIndex = state.chatHistory.findIndex(
        (conv) => conv.id === action.payload.id
      );
      if (conversationIndex !== -1) {
        const updatedChatHistory = [...state.chatHistory];
        updatedChatHistory[conversationIndex] = state.currentChat;
        return { ...state, chatHistory: updatedChatHistory };
      } else {
        return {
          ...state,
          chatHistory: [...state.chatHistory, action.payload],
        };
      }
    case "UPDATE_CHAT_TITLE":
      if (!state.chatHistory) {
        return { ...state, chatHistory: [] };
      }
      const updatedChats = state.chatHistory.map((chat) => {
        if (chat.id === action.payload.id) {
          if (state.currentChat?.id === action.payload.id) {
            state.currentChat.title = action.payload.title;
          }
          // TODO: make api call to save new title to DB
          return { ...chat, title: action.payload.title };
        }
        return chat;
      });
      return { ...state, chatHistory: updatedChats };
    case "DELETE_CHAT_ENTRY":
      if (!state.chatHistory) {
        return { ...state, chatHistory: [] };
      }
      const filteredChat = state.chatHistory.filter(
        (chat) => chat.id !== action.payload
      );
      state.currentChat = null;
      // TODO: make api call to delete conversation from DB
      return { ...state, chatHistory: filteredChat };
    case "DELETE_CHAT_HISTORY":
      // TODO: make api call to delete all conversations from DB
      return {
        ...state,
        chatHistory: [],
        filteredChatHistory: [],
        currentChat: null,
      };
    case "NEW_CHAT":
      if (!state.currentChat) {
        return state;
      }

      const updatedState = {
        ...state,
        currentChat: {
          ...state.currentChat,
          messages: [],
          id: ""
        },
      };

      return updatedState;
      
    case "DELETE_CURRENT_CHAT_MESSAGES":
      // TODO: make api call to delete current conversation messages from DB
      if (!state.currentChat || !state.chatHistory) {
        return state;
      }
      const updatedCurrentChat = {
        ...state.currentChat,
        messages: [],
      };
      return {
        ...state,
        currentChat: updatedCurrentChat,
        chatHistory: [],
      };
    case "FETCH_CHAT_HISTORY":
      return { ...state, chatHistory: action.payload };
    case "SET_COSMOSDB_STATUS":
      return { ...state, isCosmosDBAvailable: action.payload };
    case "UPDATE_USER_INFO":
      return { ...state, user: action.payload };
    default:
      return state;
  }
};
