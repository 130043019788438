import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  Spinner,
} from "@fluentui/react-components";
import styles from "./Modal.module.css";
import { ErrorCircle24Regular, Info24Regular } from "@fluentui/react-icons";
import { ReactElement } from "react";
import { darkTheme, lightTheme } from "@styles/theme";
import { useAtom, useAtomValue } from "jotai";
import { currentThemeAtom, isDarkThemeAtom } from "@state/Atoms";

interface ModalProps {
  modalType?: "error" | "wait" | "info" | undefined;
  color?: string;
  open: boolean;
  title: string;
  text: string | ReactElement;
  buttonCloseText?: string;
  buttonDoSomethingText?: string;
  doSomethingFunc?: () => void;
  closeModalFunc?: () => void;
  displayImage?: string;
}

export const Modal = (props: ModalProps) => {
  const currentTheme = useAtomValue(currentThemeAtom);

  const handleCloseModalFunc = () => {
    if (props.closeModalFunc) {
      props.closeModalFunc();
    }
  };

  const handleDoSomethinModalFunc = () => {
    if (props.doSomethingFunc) {
      props.doSomethingFunc();
    }
  };

  const modalTypeObject = () => {
    switch (props.modalType) {
      case "error":
        return (
          <ErrorCircle24Regular
            style={{ color: "#f05348", alignSelf: "center" }}
          />
        );
      case "wait":
        return <Spinner />;
      case "info":
        return (
          <Info24Regular
            style={{
              color: currentTheme.colors.icon,
              alignSelf: "center",
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={props.open}>
      <DialogSurface
        style={{
          background: currentTheme.colors.background,
        }}
      >
        <DialogBody className={styles.bodyModal}>
          <div className={styles.contentModal}>
            {modalTypeObject()}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                color: currentTheme.colors.color,
              }}
            >
              <DialogTitle>{props.title}</DialogTitle>
              <DialogContent className={styles.modalTextContainer}>
                {props.text}
              </DialogContent>
              {props.displayImage && (
                <img
                  src={props.displayImage}
                  className={styles.chatIcon}
                  aria-hidden="true"
                />
              )}
            </div>
          </div>
          <DialogActions>
            {props.buttonCloseText && (
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="secondary"
                  onClick={() => handleCloseModalFunc()}
                >
                  {props.buttonCloseText}
                </Button>
              </DialogTrigger>
            )}
            {props.buttonDoSomethingText && (
              <Button
                style={{
                  background: currentTheme.colors.backgroundButton,
                  color: currentTheme.colors.colorButton,
                }}
                appearance="primary"
                onClick={() => handleDoSomethinModalFunc()}
              >
                {props.buttonDoSomethingText}
              </Button>
            )}
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
